.grid-stack {
    border: 2px dashed #f2f2f2;
    background: #ffffff;
}

.grid-stack-item-content {
    color: #f2f2f2;
    text-align: left;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
}


.clickable-label {
    cursor: pointer;
}

.newWidget {
    cursor: grab;
}

.grid-stack-item-content {
    cursor: grab;
}

.dropzone {
    padding: 30px;
    border: 1px dashed #75868a;
    list-style: none;
}

.droppable-pdf-preview {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 1px dashed #75868a;
    list-style: none;
}

.dashed-outline {
    padding: 30px;
    border: 1px dashed #75868a;
}

.dashed-outline-small {
    padding: 5px;
    border: 1px dashed #75868a;
    margin: 5px;
}

.no-drop {
    border: 2px dashed red;
}

.can-drop {
    border: 2px dashed green;
}

.control-inner-item {
    margin-top: 10px;
}

.draggable-control-preview-item {
    margin-bottom: 10px;
    border: 1px solid #424d5c;
    cursor: grab;
}
.draggable-control-preview-item:active {
    cursor: grabbing;
}

.draggable-control {
    cursor: grab;
}

    .draggable:active {
        cursor: grabbing;
    }

.dragging {
    cursor: grabbing;
}

.editable-input {
    height: 38px;
  }

.editable-textarea {
   height: 120px;
}

.trash {
    max-width: 20px;
}

.square {
    width: 90px !important;
    height: 90px !important;
    font-size: 10px;
    padding-top: 20px;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin-left: 15px;
    margin-top: 15px;
  }

  .big-square {
    width: 150px !important;
    height: 150px !important;
    font-size: 12px;
    padding-top: 20px;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin-left: 15px;
    margin-top: 15px;
  }

 .design-element-content {
    padding-top: 10px;
    padding-bottom: 10px;
}

.step-style {
    cursor: grab;   
    background-color: #f9f9f9;
    border-radius: 10px;
    margin-top: 15px;
    height: 90px;
    overflow: hidden;
}

.step-style-square {
    width: 90px !important;
    height: 90px !important;
    font-size: 10px;
    background-color: #f2f2f2;
    padding-top: 20px;
    float:left;
    margin-right: 10px;
}

.step-style-name {
    font-size: 12px;
    padding-right: 10px;
    padding-top: 10px;    
    height: 90px;
}

.step-style-name p {
    font-size: 12px;
}

.code-editor-control {
    background-color: #f2f2f2;
    min-height: 300px;
}

.code-editor-control {
    background-color: #f2f2f2;
    min-height: 300px;
}

.list-container {
    margin-left: -25px !important;
    margin-right: -20px !important;

    display: flex;
    font-size: 18px;
    background-color: #fff;
    flex-direction: column;
  }
  .item-container {
    background-color: #fff;
    border: 1px solid black;
    padding: 25px;
    margin: 15px;
  }